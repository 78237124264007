import router from '@/router'
export default {
  namespaced: true,
  state: {
    data: null,
    role: null,
    token: null
  },
  mutations: {
    setData: (state, payload) => {
      state.data = payload
    },
    setRole: (state, payload) => {
      state.role = payload
    },
    setToken: (state, payload) => {
      state.token = payload
    },
    logout (state) {
      state.data = null
      state.token = null
      state.role = null
      localStorage.removeItem('storage')
    }
  },
  actions: {
    getData ({ commit }) {
      return new Promise((resolve, reject) => {
        this.axios.get('/user').then(response => {
          const data = response.data
          commit('setRole', data.role)
          delete data.role
          delete data.created_at
          delete data.created_at
          delete data.via
          commit('setData', data)
          resolve()
        })
          .catch(err => {
            reject(console.error(err))
          })
      })
    },
    logout ({ commit }, payload = null) {
      commit('logout')
      if (router.history.current.name !== 'login') {
        if (payload == null) {
          router.push({
            name: 'login'
          })
        } else {
          router.push({
            name: 'login',
            params: {
              error: payload
            }
          })
        }
      }
    },
    defaultTheme ({ commit }, payload) {
      alert()
    }
  },
  getters: {
    data: state => {
      return state.data
    },
    role: state => {
      return state.role
    },
    token: state => {
      return state.token
    },
    isLoggedIn: state => {
      // eslint-disable-next-line no-unneeded-ternary
      return (state.token == null ? false : true)
    }
  }
}
