<template>
  <PageTemplate title="Photo Gallery Listing">
    <template slot="header-buttons"></template>
    <template slot="content">
      <div class="card"  ref="detailsWrapper">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item list-group-item-action cursor-pointer"
            v-for="item in locations" :key="item.id"
            @click="changePage('/photos/gallery/' + item.id, item.name)">
            <i class="bi bi-arrow-return-right me-2"></i>
            <span class="text-primary"><strong>{{item.name}}</strong> - {{item.address}} {{item.id}}</span>
          </li>
        </ul>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
export default {
  components: {
    PageTemplate
  },
  data () {
    return {
      isLoading: false,
      locations: []
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.$emit('pageTitle', 'Photos')
    this.getLocations()
  },
  watch: {
    selectedLocation: {
      deep: true,
      handler (val) {
        this.getFolder(val)
      }
    }
  },
  methods: {
    getLocations () {
      this.$store.dispatch('locations/getLocations').then(res => {
        const data = res.data
        this.locations = data
      })
    }
  }
}
</script>
