/* eslint-disable */
export default {
  state: {},
  mutations: {},
  actions: {
    getFolders({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/folders?location_id=" + payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    createFolder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .post("/folders", payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(console.error(err));
          });
      });
    },
    setDefaultPhoto({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .patch("/folders/" + payload.folderId + "/photos/" + payload.photoId + "/default-photo", { status: true })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getPhotos({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/folders/" + payload + "/photos")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    addPhoto({ commit }, payload) {
      let formData = new FormData();
      formData.append("image", payload.file);
      formData.append("title", payload.title);
      console.log(payload.file);
      return new Promise((resolve, reject) => {
        this.axios
          .post("/folders/" + payload.id + "/photos", formData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateFolder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const dataPayload = {
          location_id: payload.location_id,
          name: payload.name
        };
        this.axios
          .put("/folders/" + payload.id, dataPayload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteFolder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .delete("/folders/" + payload)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deletePhoto({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .delete("/folders/" + payload.folderId + "/photos/" + payload.photoId)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {}
};
