<template>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <label  class="form-label font-w600 d-block" >Serial No</label>
      <div class="d-flex align-items-center">
        <a class="text-primary cursor-pointer" @click="changePage('/settings/RTU/' + valueRTU.id)" >{{valueRTU.serial_no ? valueRTU.serial_no : 'N/A' }}</a>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <label  class="form-label font-w600 d-block">Phone No</label>
      <div class="d-flex align-items-center">
        {{valueRTU.phone_no ? valueRTU.phone_no : 'N/A' }}
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <label  class="form-label font-w600 d-block">IP Address</label>
      <div class="d-flex align-items-center">
        {{valueRTU.ip_address ? valueRTU.ip_address : 'N/A' }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['valueRTU'],
  data () {
    return {}
  }
}
</script>
