<template>

  <div v-if="id" class="modal fade" :id="id" data-bs-backdrop="static" tabindex="-1" role="dialog" :aria-hidden="true">
    <div class="modal-dialog"
      :class="[
        centered == '' ? 'modal-dialog-centered' : '',
        large == '' ? 'modal-lg' : '',
        small == '' ? 'modal-sm' : '',
        full == '' ? 'modal-fullscreen' : ''
      ]"
      role="document">
      <div class="modal-content" v-if="!gallery">
        <div class="modal-header">
          <h5 class="modal-title hide-on-empty">{{title}}<slot name="title" /></h5>
          <button type="button" class="btn-close"  @click="close()" data-bs-toggle="modal" :data-bs-target="'#' + id" />
        </div>
        <div class="modal-body">
          <slot name="content" />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
      <div class="modal-content bg-transparent" v-else>
        <div class="modal-header border-bottom-0" style="background-color:rgba(0,0,0,0.95)">
          <h5 class="modal-title hide-on-empty text-light" style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{title}}<slot name="title" /></h5>
          <button type="button" class="btn-close text-white p-0" style="font-size: 30px; line-height: 1 "  @click="close()" data-bs-toggle="modal" :data-bs-target="'#' + id" >
            <i class="bi bi-x" ></i>
          </button>
        </div>
        <div style="background-color:rgba(0,0,0,0.95)" class="p-3 flex-grow-1 d-flex justify-content-center"><slot name="content" /></div>
        <div class="modal-footer border-top-0" style="background-color:rgba(0,0,0,0.95)">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: ['title', 'hide-title', 'show', 'centered', 'large', 'small', 'id', 'gallery', 'full'],
  data () {
    return {}
  },
  watch: {
  },
  created () {
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style>
/* .modal.show {
  display: block;
  padding-right: 17px;
} */
</style>
