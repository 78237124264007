/* eslint-disable */
export default {
  namespaced: true,
  state: {
    isMobile: localStorage.getItem("isMobile") || null
  },
  mutations: {
    set_state_isMobile(state, payload) {
      localStorage.setItem("isMobile", payload);
      state.isMobile = payload;
    }
  },
  actions: {},
  getters: {
    mobileView(state) {
      return state.isMobile && state.isMobile != null ? state.isMobile : null;
    }
  }
};
