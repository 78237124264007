<template>
  <div :class="cssClass">
    <slot name="breadcrumb" />
    <div class="d-flex align-items-center flex-wrap" :class="hideTitle ? '' : 'mb-3'">
      <div class="flex-grow-1">
        <h4 class="fs-20 font-w600 mb-0">{{title}}<slot name="title" /></h4>
      </div>
      <div class="">
        <slot name="header-buttons" />
      </div>
    </div>
    <div>
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'hideTitle', 'cssClass'],
  data () {
    return {}
  }
}
</script>
