<template>
  <div>
    <Breadcrumb>
      <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push('/settings/users/')">Users</a></li>
      <li class="breadcrumb-item active"><a>Detail</a></li>
    </Breadcrumb>
    <Toolbar>
      <button @click="isEdit = !isEdit, closeAlert()" class="btn btn-primary btn-sm me-3" title="Edit">
        <i v-if="!isEdit" class="fas fa-edit"></i>
        <i v-else class="fas fa-arrow-circle-left"></i>
      </button>
      <button v-if="!isEdit" data-bs-toggle="modal" data-bs-target="#modalConfirmation" class="btn btn-danger btn-sm me-3" title="Delete">
        <i v-if="!isEdit" class="fas fa-trash"></i>
      </button>
    </Toolbar>
    <div class="row" ref="profileCont">
      <div class="col-xs-12">
        <Alert :type="'danger'" v-if="error" @close="closeAlert">
          <template v-slot:title>Error</template>
          <template v-slot:content>
            <ol>
              <template v-for="field in error">
                <template v-for="errs in field">
                  <li v-for="err in errs" :key="err">{{ err }}</li>
                </template>
              </template>
            </ol>
          </template>
        </Alert>
        <ProfileDetail v-if="!isEdit" :user="user"></ProfileDetail>
        <ProfileEdit :isAdmin="true" v-if="isEdit" :user="user" @save="save"></ProfileEdit>
      </div>
    </div>

    <!-- Modal Confirmation Delete -->
    <div class="modal fade" id="modalConfirmation">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation Delete</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">Are you sure want to delete this user?</div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary" @click="deleteUser()" data-bs-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Base/Breadcrumb'
import Toolbar from '@/components/Base/Toolbar'
import Alert from '@/components/Alert'
import ProfileDetail from '@/components/Profile/Detail'
import ProfileEdit from '@/components/Profile/Edit'
export default {
  name: 'users-detail',
  data: () => {
    return {
      isEdit: false,
      user: null,
      error: null
    }
  },
  components: {
    Breadcrumb, Toolbar, Alert, ProfileDetail, ProfileEdit
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  mounted () {
    /* Page Title */
    this.$emit('pageTitle', 'User Detail')

    this.getUser()
  },
  methods: {
    getUser () {
      this.loading(this.$refs.profileCont, 'show')
      this.axios.get(`/users/${this.id}`).then(response => {
        this.user = response.data
        this.loading(this.$refs.profileCont, 'hide')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.profileCont, 'hide')
      })
    },
    save (data) {
      this.loading(this.$refs.profileCont, 'show')
      this.axios.put(`/users/${this.id}`, data).then(response => {
        this.user = response.data.user
        this.loading(this.$refs.profileCont, 'hide')
        this.$toast.success(response.data.message)
        this.isEdit = false
      }).catch(err => {
        this.error = err.response.data
        this.loading(this.$refs.profileCont, 'hide')
      })
    },
    deleteUser () {
      this.loading(this.$refs.profileCont, 'show')
      this.axios.delete(`/users/${this.id}`).then(response => {
        this.loading(this.$refs.profileCont, 'hide')
        this.$toast.success(response.data.message)
        this.isEdit = false
        this.$router.push('/settings/users')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.profileCont, 'hide')
        this.$toast.error(err.response.data.error)
      })
    },
    closeAlert () {
      this.error = null
    }
  }
}
</script>
