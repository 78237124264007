<template>
  <div v-if="user">
    <div class="card">
      <div class="card-body">
        <div class="basic-form">
          <form>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="userData.name" placeholder="Name">
              </div>
            </div>
            <div class="mb-3 row" v-if="isAdmin">
              <label class="col-sm-3 col-form-label">Role</label>
              <div class="col-sm-9">
                <select class="form-control" v-model="userData.role" placeholder="Role" ref="role">
                  <option v-for="role in roles" :value="role" :key="role">{{ role | capitalise }}</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card-footer text-center">
        <a @click="save()" class="btn btn-primary">Save</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'profile-edit',
  data: () => {
    return {
      userData: [],
      roles: []
    }
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.userData = this.user

    if (this.isAdmin) this.getRoles()
  },
  methods: {
    save () {
      this.$emit('save', this.userData)
    },
    getRoles () {
      this.loading(this.$refs.role, 'show')
      this.axios.get('/roles').then(response => {
        this.roles = response.data
        this.loading(this.$refs.role, 'hide')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.role, 'hide')
      })
    }
  }
}
</script>
