<template>
  <div class="row page-titles">
    <ol class="breadcrumb">
      <slot></slot>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'base-breadcrumb'
}
</script>
