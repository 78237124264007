<template>
  <PageTemplate title="">
      <template slot="breadcrumb">
      </template>
      <template slot="title">
        <a @click="changePage('/settings/rtu')" class="text-primary custom-link cursor-pointer">RTU Listing</a>
        <i class="fas fa-chevron-right ms-2 me-2 text-gray-500"></i>
        <span v-if="this.$route.path !== '/settings/rtu/create'">RTU Details</span>
        <span v-else>Create New RTU</span>
      </template>
      <template slot="header-buttons">
        <template  v-if="this.$route.path !== '/settings/rtu/create'">
          <template v-if="!isMobileView">
            <a class="btn btn-primary me-3 btn-sm" v-if="!editmode" @click="editmode = true" :disabled="isLoading"><i class="fas fa-edit me-2 fa-lg"></i>EDIT</a>
            <a class="btn btn-primary me-3 btn-sm" v-if="editmode" @click="saveRTUDetails()" :disabled="isLoading"><i class="fas fa-save me-2 fa-lg"></i>SAVE</a>
            <a class="btn btn-danger btn-sm" v-if="editmode" @click="initDetails()" :disabled="isLoading"><i class="fas fa-times me-2 fa-lg" ></i>CANCEL</a>
            <a class="btn btn-danger btn-sm" ref="deleteDialogBtn" v-if="!editmode" :disabled="isLoading" @click="deleteDialog = true" data-bs-toggle="modal" data-bs-target="#closeDialog"><i class="fas fa-trash me-2"></i>Delete</a>
          </template>
          <div class="dropdown custom-dropdown mb-0" v-else>
            <div class="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" v-if="!editmode" @click="editmode = true" :disabled="isLoading"><i class="fas fa-edit me-2"></i>EDIT</a>
              <a class="dropdown-item" v-if="editmode" @click="saveRTUDetails()" :disabled="isLoading"><i class="fas fa-save me-2"></i>SAVE</a>
              <a class="dropdown-item" v-if="editmode" @click="initDetails()" :disabled="isLoading"><i class="fas fa-times me-2" ></i>CANCEL</a>
              <a class="dropdown-item" ref="deleteDialogBtn" v-if="!editmode" :disabled="isLoading" @click="deleteDialog = true" data-bs-toggle="modal" data-bs-target="#closeDialog"><i class="fas fa-trash me-2"></i>DELETE</a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="dropdown custom-dropdown mb-0" v-if="isMobileView" >
            <div class="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" @click="submitRTUDetails()" :disabled="isLoading"><i class="fas fa-save me-2"></i>SAVE</a>
            </div>
          </div>
          <a class="btn btn-primary btn-sm" @click="submitRTUDetails()" :disabled="isLoading" v-else><i class="fas fa-save me-2 fa-lg"></i>SAVE</a>
        </template>
      </template>
      <template slot="content">
        <div class="row" v-if="details !== null">
          <div class="col-xl-12">
            <div class="card" ref="detailsWrapper">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6  col-md-6  py-3">
                    <label  class="form-label font-w600">Serial No<span class="text-danger scale5 ms-2">*</span></label>
                    <input :disabled="!editmode" type="number" class="form-control" placeholder="Serial No" v-model="details.serial_no">
                  </div>
                  <div class="col-xl-6  col-md-6  py-3">
                    <label  class="form-label font-w600">IP Address<span class="text-danger scale5 ms-2">*</span></label>
                    <input :disabled="!editmode" type="text" class="form-control" placeholder="IP Address" v-model="details.ip_address">
                  </div>
                  <div class="col-xl-6  col-md-6  py-3">
                    <label  class="form-label font-w600">Phone No<span class="text-danger scale5 ms-2">*</span></label>
                    <input :disabled="!editmode" type="text" class="form-control" placeholder="Phone No" v-model="details.phone_no">
                  </div>
                  <div class="col-xl-6  col-md-6 py-3">
                    <label  class="form-label font-w600">Location<span class="text-danger scale5 ms-2">*</span></label>
                    <select :disabled="!editmode" class="form-select form-control" v-model="details.location_id">
                      <option :selected="details.location_id == null ? true : false" :value="null">Choose...</option>
                      <option v-for="list in locations" :value="list.id" :key="list.id">{{list.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Dialog-->
        <Dialog :id="'closeDialog'" @close="deleteDialog = false" centered small title="Delete RTU" >
          <template slot="content">
            <p>Are you sure want to delete?</p>
          </template>
          <template slot="footer">
            <a class="btn btn-danger btn-sm  light" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#closeDialog">cancel</a>
            <a class="btn btn-danger btn-sm" :disabled="isLoading" @click="deleteRTU">DELETE RTU</a>
          </template>
        </Dialog>
      </template>
    </PageTemplate>
</template>

<script>
import PageTemplate from '@/components/Templates/PageTemplate'
import Dialog from '@/components/Templates/Dialog'
export default {
  components: {
    PageTemplate,
    Dialog
  },
  data () {
    return {
      details: null,
      editmode: false,
      oldDetails: null,
      locations: [],
      isLoading: false,
      deleteDialog: false
    }
  },
  created () {
    this.$emit('pageTitle', 'RTU')
    this.initDetails()
  },
  computed: {
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  methods: {
    async initDetails () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.editmode = false
      const locations = await this.$store.dispatch('locations/getLocations')
      if (this.$route.path !== '/settings/rtu/create') {
        const details = await this.$store.dispatch('getRTUDetails', this.$route.params.id)
        this.details = details.data
      } else {
        this.details = {
          serial_no: null,
          ip_address: '',
          phone_no: '',
          location_id: null
        }
        this.editmode = true
      }
      this.locations = locations.data
      this.loading(this.$refs.detailsWrapper, 'hide')
    },
    saveRTUDetails () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      const payload = {
        serial_no: this.details.serial_no,
        ip_address: this.details.ip_address,
        phone_no: this.details.phone_no,
        location_id: this.details.location_id,
        id: this.details.id
      }
      this.$store.dispatch('saveRTUDetails', payload)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.editmode = false
          this.$router.push('/settings/rtu')
        })
        .catch(err => {
          console.log(err)
          this.editmode = true
          // this.$toast.error(response.data.message)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    },
    submitRTUDetails () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('createRTU', this.details)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.$router.push('/settings/rtu')
        })
        .catch(err => {
          console.log(err)
          // this.$toast.error(response.data.message)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    },
    deleteRTU () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('deleteRTU', this.details.id)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.$router.push('/settings/rtu')
          this.$refs.deleteDialogBtn.click()
        })
        .catch(err => {
          console.log(err)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    }
  },
  watch: {
  }
}
</script>
