<template>
  <center>
    <div class="centered">
      <i class="fas fa-spinner fa-spin"></i><br><br>
      Verifying
    </div>
  </center>
</template>

<script>
export default {
  name: 'login-success',
  mounted () {
    const vm = this
    const token = this.$route.params.token
    /* this.$router.replace({ name: 'login-success', params: { token: null } }) */
    this.$store.commit('user/setToken', token)

    this.axios.defaults.headers = {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json'
    }

    this.axios.get('/sanctum/csrf-cookie').then(response => {
      vm.$store.dispatch('user/getData', token).then((response) => {
        vm.$router.push({ name: 'dashboard' })
        /* location.href = '/home' */
      })
    })
  }
}
</script>
<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.fas {
  font-size: 5rem;
}
</style>
