<template>
  <div>
    <div class="d-sm-flex d-block justify-content-between align-items-center mb-4">
      <div class="card-action coin-tabs mt-3 mt-sm-0">
        <ul class="nav nav-tabs ml-0" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#active"  @click="getActiveUser()">Active</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " data-bs-toggle="tab" href="#pending" @click="getPendingUser()">Pending</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="tab-content">
          <div class="tab-pane fade show active" id="active" ref="tblActive">
            <vue-good-table styleClass="vgt-table bordered"
              @on-row-click="onRowClick"
              :columns="active.columns"
              :rows="active.rows"
              :search-options="{
                enabled: true,
                placeholder: 'Search active user'
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'created_at', type: 'asc'}
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: 5
              }"
              :theme="theme.themeVersion === 'dark' ? 'nocturnal' : null ">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'created_at'">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY hh:mmA') }}
                  </div>
                  <div v-else-if="props.column.field == 'role'">
                    {{ props.row.role.charAt(0).toUpperCase() + props.row.role.slice(1) }}
                  </div>
                  <div v-else>
                    {{props.formattedRow[props.column.field]}}
                  </div>
                </template>
                <div slot="emptystate">
                  <center>No active users found</center>
                </div>
              </vue-good-table>
          </div>
          <div class="tab-pane fade" id="pending" ref="tblPending">
            <vue-good-table styleClass="vgt-table bordered"
              :columns="pending.columns"
              :rows="pending.rows"
              :search-options="{
                enabled: true,
                placeholder: 'Search pending user'
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'created_at', type: 'asc'}
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: 5
              }"
              :theme="theme.themeVersion === 'dark' ? 'nocturnal' : null ">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'created_at'">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY hh:mmA') }}
                  </div>
                  <div v-else-if="props.column.field == 'action'" :ref="'btnAction_'+props.row.id">
                    <button class="btn btn-outline-primary btn-xs" @click="registerAction(props.row.id, 'approve')">
                      <i class="fas fa-check btn-action"></i>
                    </button>&nbsp;
                    <button class="btn btn-outline-danger btn-xs" @click="registerAction(props.row.id, 'reject')">
                      <i class="fas fa-times btn-action"></i>
                    </button>
                  </div>
                  <div v-else>
                    {{props.formattedRow[props.column.field]}}
                  </div>
                </template>
                <div slot="emptystate">
                  <center>No pending users found</center>
                </div>
              </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'users-list',
  data: () => {
    return {
      active: {
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Email',
            field: 'email',
            width: '280px'
          },
          {
            label: 'Registered on',
            field: 'created_at',
            thClass: 'text-left',
            tdClass: 'text-left'
          },
          {
            label: 'Role',
            field: 'role',
            thClass: 'text-left',
            tdClass: 'text-left'
          }
        ],
        rows: {}
      },
      pending: {
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Email',
            field: 'email',
            width: '280px'
          },
          {
            label: 'Registered on',
            field: 'created_at',
            thClass: 'text-left',
            tdClass: 'text-left'
          },
          {
            label: '',
            field: 'action',
            tdClass: 'text-center',
            sortable: false,
            width: '150px',
            html: true
          }
        ],
        rows: {}
      }
    }
  },
  components: {
    VueGoodTable
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  mounted () {
    /* Page Title */
    this.$emit('pageTitle', 'Users')

    this.getActiveUser()
    this.getPendingUser()
  },
  methods: {
    getActiveUser () {
      this.loading(this.$refs.tblActive, 'show')
      this.axios.get('/users').then(response => {
        this.active.rows = response.data
        this.loading(this.$refs.tblActive, 'hide')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.tblActive, 'hide')
      })
    },
    getPendingUser () {
      this.loading(this.$refs.tblPending, 'show')
      this.axios.get('/user-registers').then(response => {
        this.pending.rows = response.data
        this.loading(this.$refs.tblPending, 'hide')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.tblPending, 'hide')
      })
    },
    registerAction (id, status) {
      this.loading(this.$refs[`btnAction_${id}`], 'show')
      this.axios.post(`/user-registers/${id}/action`, {
        status
      }).then(response => {
        const removeIndex = this.pending.rows.map(row => row.id).indexOf(id)
        this.pending.rows.splice(removeIndex, 1)

        this.loading(this.$refs[`btnAction_${id}`], 'hide')
        this.$toast.success(response.data.message)
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs[`btnAction_${id}`], 'hide')
        this.$toast.error(err.response.data.error)
      })
    },
    onRowClick (event) {
      this.$router.push({
        path: `/settings/users/${event.row.id}`
      })
    }
  }
}
</script>
<style>
.vgt-inner-wrap {
  border-radius: 1.25rem;
}

.vgt-global-search {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.vgt-wrap__footer {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.vgt-table.bordered td, .vgt-table.bordered th {
  border: 1px solid #eeeeee;
  color: black;
  font-family: 'poppins', sans-serif;
  font-size: 1rem;
}

.vgt-wrap__footer .footer__row-count__label, .vgt-wrap__footer .footer__navigation,
.vgt-wrap__footer .footer__navigation__page-btn span, .vgt-wrap__footer .footer__row-count__select {
  font-family: 'poppins', sans-serif;
  font-size: 1rem;
}

.vgt-global-search, .vgt-table thead th, .vgt-wrap__footer {
  background: #fff;
}

.vgt-table {
  font-size: 0.5rem;
}

.vgt-global-search__input {
  margin: 1rem;
}

.vgt-table thead th {
  color: #000000;
}

/* dark */
.vgt-wrap.nocturnal .vgt-global-search {
  border: 1px solid #2e2e42;
  background: #212130;
}

.vgt-wrap.nocturnal .vgt-global-search__input .vgt-input, .vgt-wrap.nocturnal .vgt-global-search__input .vgt-select {
  color: #c7ced8;
  background-color: #17171e;
  border: 1px solid #2e2e42;
}

.vgt-table.nocturnal {
  border: 1px solid #2e2e42;
  background-color: #212130;
}

.vgt-table.nocturnal.bordered td, .vgt-table.nocturnal.bordered th {
  border: 1px solid #2e2e42;
  color: #ffffff;
}

.vgt-table.nocturnal thead th {
  color: #c7ced8;
  background: #212130;
}

.vgt-wrap.nocturnal .vgt-wrap__footer {
  color: #c7ced8;
  border: 1px solid #2e2e42;
  background: linear-gradient(#212130,#212130);
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-info__current-entry {
  color: #c7ced8;
  background: #17171e;
  border: 1px solid #2e2e42;
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count__select {
  background: #17171e;
}
</style>

<style scoped>
.btn-action {
  font-size: 1rem;
  margin-top: 2px;
}
</style>
