module.exports = {
  filters: {
    capitalise: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    $route () {
      // Always close sidemenu when navigate to new page
      // eslint-disable-next-line no-undef
      $('#main-wrapper').removeClass('menu-toggle')
      // eslint-disable-next-line no-undef
      $('.hamburger').removeClass('is-active')
    }
  },
  computed: {
    role () {
      return this.$store.getters['user/role']
    }
  },
  methods: {
    loading (el, status) {
      /*
       * Usage:
       * this.loading(this.$refs.profileCont, 'show')
       *
       * Element:  id, ref or class name
       * Status:   show or hide
       */

      // eslint-disable-next-line no-undef
      const customElement = $('<div>', {
        class: 'dot-falling'
      })
      // eslint-disable-next-line no-undef
      $(el).LoadingOverlay(status, {
        image: '',
        background: 'rgb(0 0 0 / 5%)',
        custom: customElement
      })
    },
    changePage (val, title) {
      this.$router.push({ path: val, query: { pageName: title || '' } })
    },
    changeTheme (theme) {
      this.$store.dispatch('config/changeTheme', theme)
    },
    onResize () {
      console.log('change')
      if (window.innerWidth < 960) {
        this.$store.commit('global/set_state_isMobile', true)
      } else this.$store.commit('global/set_state_isMobile', false)
    }
  }
}
